import React, {Component} from 'react';
import { FaFacebookF,FaInstagram,FaWhatsapp,FaTelegramPlane } from 'react-icons/fa';
import styles from '../App.module.scss';
class HeaderSocials extends Component {
    render() {
        return (
            <>
                <a href="/" className={styles.hsIcon}>
                    <FaFacebookF/>
                </a>
                <a href="/" className={styles.hsIcon}>
                    <FaInstagram/>
                </a>
                <a href="/" className={styles.hsIcon}>
                    <FaWhatsapp/>
                </a>
                <a href="/" className={styles.hsIcon}>
                    <FaTelegramPlane/>
                </a>
            </>

        );
    }
}

export default HeaderSocials;