import React from 'react';
import styles from '../App.module.scss';
import AboutImg from '../img/about.png'
const About = () => {

    return (
        <section id={'about'}>
            <h2 className={styles.sectionTitle} >О нас</h2>
            <div className={'grid lg:grid-cols-2 gap-6'}>
                <div className={'font-light text-[#383838]'}>
                    <p className={'mb-5'}>
                        <span className={'text-primary'}>Pharm IQ</span> - надежный партнер для своих клиентов. Как
                        эксперты мы помогаем в разработке и оптимизации операционной
                        деятельности, благодаря чему наши партнеры могут посвятить больше времени для решения ключевых
                        приоритетов и
                        повышению эффективности ведения бизнеса.
                    </p>
                    <p className={'mb-5'}>
                        <span className={'text-primary uppercase'}>Наша миссия</span> Предоставить широкий спектр
                        решений,
                        отвечающих запросам фармацевтического бизнеса и направленных на усовершенствование коммуникации
                        между всеми участниками области здравоохранения с заботой о пациентах.
                    </p>
                    <p className={'mb-5'}>
                        <span className={'text-primary uppercase'}>Наша команда</span> Команда Pharm IQ собрана из
                        лучших экспертов, обладающих широким опытом на фармацевтическом рынке. Все сотрудники компании
                        хорошо знакомы с особенностями и механизмами рынка, умеют оценить возможности и предвидеть
                        тренды.
                    </p>
                </div>
                <div>
                    <img src={AboutImg} alt="о нас" className={'mx-auto'}/>
                </div>
            </div>
        </section>
    );

}

export default About;