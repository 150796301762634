export const services = [
    {
        'title': 'Аутсорсинг медицинских представителей',
        'description': 'Достижение Ваших целей при оптимизации ресурсов и затрат',
        'extra': {
            'left': {
                'title': 'Что входит:',
                'content': [
                    'Бесплатный подбор представителей',
                    'Обучение и персональный контроль сотрудников',
                    'Прозрачная отчетность',
                    'Разные каналы взаимодействия (F2F, online, звонки)'
                ]
            },
            'right': {
                'title': 'Преимущества аутсорса:',
                'content': [
                    'Большой опыт в работе с аутсорсом',
                    'Оптимизация затрат и ресурсов',
                    'Не требуется оформление в штат',
                    'Широкое покрытие в регионах РФ',
                    'Гибкие условия и внесение изменений в рамках проекта'
                ]
            }
        }
    },
    {
        'title': 'Обучение и аудит медицинских представителей',
        'description': 'Объективная картина эффективности работы полевых сотрудников вашей компании. \n' +
            'Выявляем негативные стороны и находим пути устранения',
        'extra': {
            'left': {
                'title': null,
                'content': [
                    'Через базу клиентов : По телефону / Онлайн',
                    'F2F визиты по базе медицинского представителя',
                    'Двойные визиты с медицинским представителем',
                    'Аудит на знание продукции'
                ]
            },
            'right': null
        }
    },
    {
        'title': 'Контроль выполнения договоров с аптечными сетями',
        'description': 'Важный инструмент увеличения продаж ',
        'extra': {
            'left': {
                'title': 'Что входит:',
                'content': [
                    'Прозрачная и своевременная отчетность',
                    'Разные каналы взаимодействия (F2F, online, звонки)',
                ]
            },
            'right': {
                'title': 'Ваша компания получит:',
                'content': [
                    'Полную прозрачность в работе аптечной сети',
                    'Наличие неснижаемого запаса продукции',
                    'Стабильно высокие продажи по всем аптекам',
                    'Прирост всего портфеля или части его',

                ]
            }
        }
    },
    {
        'title': 'Мультиканальное продвижение',
        'description': 'Увеличение числа контактов с целевой аудиторией за счет таргетных рассылок,\n' +
            'работы колл-центра, вебинаров, онлайн/офлайн визитов и других активностей',
        'extra': {
            'left': {
                'title': 'Возможны варианты продвижения:',
                'content': [
                    'База клиентов Pharm IQ',
                    'База клиентов Заказчика',
                ]
            },
            'right': null
        }
    },
    {
        'title': 'Анализ и экспертиза фармацевтического рынка',
        'description': 'Это профессиональный подход в планировании, сборе данных,' +
            ' обработке и анализе полученных результатов по любому из видов исследований фармацевтического рынка:',
        'extra': {
            'left': {
                'title': null,
                'content': [
                    'Опрос и анкетирование врачей и фармацевтов',
                    'Глубинное интервью',
                    'Фокус-группы',
                    'Дневниковые наблюдения',
                    'Телефонные опросы',
                    'Тайный покупатель (Mystery shopper)',
                    'Трекинг бренда (Brand Tracking)',
                    'Мониторинговые программы пациентов',
                    'Диагностические программы и подбор пациентов',
                ]
            },
            'right': null
        }
    },
    {
        'title': 'Анализ целевых аудиторий и потребительских предпочтений',
        'description': 'Знание и понимание целевой аудитории услуги/продукта является залогом успеха',
        'extra': {
            'left': {
                'title': 'Pharm IQ предлагает комплексный подход к решению поставленных задач:',
                'content': [
                    'Предварительное исследование конкурентного рынка',
                    'Определение целевой аудитории вашего продукта',
                    'Подбор и выбор инструментов анализа',
                    'Выбор методов коммуникации',
                ]
            },
            'right': null
        }
    },
    {
        'title': 'Образовательные программы для врачей и провизоров',
        'description': 'Эффективный инструмент по взаимодействию с ЦА для продвижения продуктов',
        'extra': {
            'left': {
                'title': 'Что входит:',
                'content': [
                    'Формирование программы',
                    'Привлечение экспертов и KOLs',
                    'Подбор и согласование площадки',
                    'Интерактивная поддержка мероприятий: видео-трансляция, запись мероприятия, email рассылка приглашений участникам и их регистрация',
                    'Продвижение мероприятия в интернете',
                ]
            },
            'right': null
        }
    },

];

