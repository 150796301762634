import React from 'react';
import styles from '../App.module.scss';
import LogoBlack from '../img/logo_black.svg';
import Pin from '../img/pin.svg';
import Phone from '../img/phone.svg';
import Email from '../img/email.svg';
const Contacts = () => {

    return (
        <section id={'contacts'}>
            <h2 className={styles.sectionTitle}>Контакты</h2>
            <div className={'md:flex-row flex-col flex md:items-center items-start mb-10'}>
                <img src={LogoBlack} alt="PharmIQ" className={'w-[170px] mr-5'}/>
                <div className={'font-light text-[#383838] flex flex-col md:ml-0 ml-5'}>
                    <div className={'flex mb-3'}>
                        <img src={Pin} alt="Адрес" className={'h-[30px] w-[27px] mr-3'}/>
                        <span>109428, г.Москва, ул Зарайская д 21</span>
                    </div>
                    {/* <div className={'flex mb-3'}>
                        <img src={Phone} alt="Телефон" className={'h-[27px] w-[27px] mr-3'}/>
                        <span>
                            <a href="tel:+74955555555">+7(495)555 55 55</a>
                        </span>
                    </div> */}
                    <div className={'flex'}>
                        <img src={Email} alt="Email" className={'h-[27px] w-[27px] mr-3'}/>
                        <span>
                            <a href="mailto:vm@pharm-iq.ru">vm@pharm-iq.ru</a>
                        </span>
                    </div>
                </div>

            </div>
        </section>
    );

}

export default Contacts;