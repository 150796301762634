import './App.css';
import FirstScreen from "./components/FirstScreen";
import Screen2 from "./components/Screen2";
import About from "./components/About";
import Services from "./components/Services";
import Contacts from "./components/Contacts";
import MapY from "./components/Map";
import Footer from "./components/Footer";


function App() {
    return (
        <>
            <FirstScreen/>
            <div className={'px-5'}>
                <div className={'bg-white container mx-auto rounded-xl shadow-lg mt-[-490px] md:mt-[-390px] lg:mt-[-360px]  py-16 mb-5 px-5 md:px-10'}>
                    <Screen2/>
                    <About/>
                    <Services/>
                    <Contacts/>
                    <MapY/>                   
                </div>
            </div>
            <Footer/>

        </>
    );
}

export default App;
