import React from 'react';

import {data} from '../data/screen2'


const Screen2 = ()=> {
        return (
            <div className={'grid lg:grid-cols-2 gap-6'}>
                {
                    data.map((item,key) => {
                       return(
                               <div
                                   key={key}
                                   className={'flex mb-5'}
                               >
                                   <img
                                       src={item.icon}
                                       alt={item.title}
                                       className={'w-[30px] h-[30px] mr-3'}
                                   />
                                <div className={'text-[#383838]'}>
                                    <h3
                                        className={'uppercase font-semibold text-[16px] md:text-[18px] lg:text-[20px] mb-2'}
                                    >{item.title}</h3>
                                    <p className={'font-light text-[14px] md:text-[16px]' }>{item.description}</p>
                                </div>

                               </div>


                           )

                    })
                }
            </div>
        );

}

export default Screen2;