import React, {Component} from 'react';
import styles from '../App.module.scss'
import Header from "./Header";
class FirstScreen extends Component {
    render() {
        return (
            <div className={styles.firstScreen}>
                <div className={'px-5'}>
                <Header/>
                </div>
                <h1 className={'text-white md:text-[42px]  sm:text-[28px]  text-[20px] flex text-center md:mt-16 mt-10 uppercase justify-center font-semibold'}>
                    Комплексные решения <br/>
                    для поддержки <br/>
                    фармацевтического бизнеса
                </h1>
            </div>
        );
    }
}

export default FirstScreen;