export const data = [
    {
        'icon': require("../img/s2-1.png"),
        'title':'Подбор персонала и оптимизация\n' +
            'коммерческих процессов ',
        'description':'Оперативный подбор и целенаправленное развертывание \n' +
            'команды сотрудников для решения бизнес задач.'
    },
    {
        'icon':require('../img/sc2-2.png'),
        'title':'Адаптивная модель развертывания \n' +
            'полевых сил',
        'description':'Выбор рабочей модели полевых сил, которая соответствует \n' +
            'бизнес целям заказчика: F2F , online, call-center, рассылки или \n' +
            'комбинированная гибридная модель.'
    },
    {
        'icon':require('../img/sc2-3.png'),
        'title':'Маркетинг',
        'description':'Оценка конкурентного окружения, анализ и разработка\n' +
            'стратегии продвижения и контента для омниканального\n' +
            'маркетинга.'
    },
    {
        'icon':require('../img/sc2-4.png'),
        'title':'Проникновение на рынок',
        'description':'Эффективное взаимодействие с товаропроводящей сетью. \n' +
            'Проведение переговоров и помощь в заключении контрактов \n' +
            'с дистрибьюторами и аптечными сетями.'
    },
];

