import React from 'react';
// import styles from '../App.module.scss';
// import {FaFacebookF, FaInstagram, FaTelegramPlane, FaWhatsapp} from "react-icons/fa";

const Footer = () => {

    return (
        <div className={'flex container mx-auto items-center justify-around p-5'}>
            <div className={'font-light text-[#383838]'}>
                    <span>
                        © 2023, PharmIQ
                    </span>

            </div>
            {/* <div className="fSocials flex flex-row sm:space-x-5 space-x-2">
                <a href="/" className={styles.fsIcon}>
                    <FaFacebookF/>
                </a>
                <a href="/" className={styles.fsIcon}>
                    <FaInstagram/>
                </a>
                <a href="/" className={styles.fsIcon}>
                    <FaWhatsapp/>
                </a>
                <a href="/" className={styles.fsIcon}>
                    <FaTelegramPlane/>
                </a>
            </div> */}

        </div>

    );

}

export default Footer;