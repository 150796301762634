
import React,{useState} from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';


const Form = ()=> {

const [form, setForm] = useState({
    name: "",
    phone: "",
    service: "",
});

const [nameField,setNameField]= useState('gray-300');
const [nameValid,setNameValid]= useState(false);
const [phoneField,setPhoneField]= useState('gray-300');
const [phoneValid,setPhoneValid]= useState(false);
const [serviceField,setServiceField]= useState('gray-300');
const [serviceValid,setServiceValid]= useState(false);
const [mailSend, setMailSend]= useState(false)



const countDigitsInString = (str) => {
    return str.replace(/[^0-9]/g, '').length;
  }
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };



const onUpdateField = (e) => {
   
    
    if(e.target.name === 'name'){
      
       
        if(verifyLength(e.target.value,2)){
            setNameField('indigo-500');   
            setNameValid(true);           
        }
        else{
           
            setNameField('red-600');
            setNameValid(false);         
        }
       
    } 
    
    if(e.target.name === 'phone'){
        if( countDigitsInString(e.target.value) < 11){
       
            setPhoneField('red-600');
            setPhoneValid(false);    
            
        }
        else{
            setPhoneField('indigo-500');
            setPhoneValid(true);
            ;
        }
    } 


    if(e.target.name === 'service'){
        if(e.target.value.length < 3){
       
            setServiceField('red-600');
            setServiceValid(false);
           
        }
        else{
            setServiceField('indigo-500');
            setServiceValid(true);
           
        }     
    }

    
    const nextFormState = {
        ...form,
        [e.target.name]: e.target.value,
      };
    setForm(nextFormState);
   
  };
const onSubmitForm = e => {
    e.preventDefault();    
   
   
    axios
        .post("https://pharm-iq.com/form_handler.php", form, {
          headers: {
            "Content-Type": "application/json"
          },
        })
        .then((res) => {
          if(res.data === 'Message has been sent'){
                setMailSend(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
};

  return (

    <div>
        {
         mailSend ? 
         <div className='flex justify-center items-center text-lg p-10'>
            Спасибо, ваша заявка отправлена!
         </div> :
         
         <form onSubmit={onSubmitForm}>              
         <div className="mt-2">
            <p className="text-sm text-gray-500">
                Оставьте ваши контакты и мы свяжемся с вами в ближайшее время!
            </p>
        </div>
         <div className="flex flex-col my-5">
             <div className='w-full mb-3'>
                 <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                     Ваше имя
                 </label>
                 <input
                     type="text"
                     name="name"
                     id="name"
                     autoComplete="name"
                     className={"border-"+ nameField + "  outline-none focus:border-" + nameField + " mt-1 py-2 px-3 block w-full rounded-md border  shadow-sm sm:text-sm"}
                     value={form.name}
                     onChange={onUpdateField}
                 />
             </div>
             <div className='w-full mb-3'>
                 <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                     Телефон
                 </label>                
                 <InputMask
                     mask={'+7(999)999 99 99'}
                     name="phone"
                     id="phone"
                     autoComplete="phone"
                     className={"border-"+ phoneField + "  outline-none focus:border-" + phoneField + " mt-1 py-2 px-3 block w-full rounded-md border  shadow-sm sm:text-sm"}
                     value={form.phone}
                     onChange={onUpdateField}
                 />
             </div>

             <div className="col-span-6 sm:col-span-3">
                 <label htmlFor="service" className="block text-sm font-medium text-gray-700">
                     Услуга
                 </label>
                 <select
                     id="service"
                     name="service"
                     autoComplete="service"
                     className={"border-"+ serviceField + "  outline-none focus:border-" + serviceField + " mt-1 py-2 px-3 block w-full rounded-md border  shadow-sm sm:text-sm"}
                     onChange={onUpdateField}
                     value={form.service}

                 >
                     <option value={0}>Выбрать услугу</option>
                     <option value={'Аутсорсинг медицинских представителей'}>Аутсорсинг медицинских представителей</option>
                     <option value={'Обучение и аудит медицинских представителей'}>Обучение и аудит медицинских представителей</option>
                     <option value={'Контроль выполнения договоров с аптечными сетями'}>Контроль выполнения договоров с аптечными сетями</option>
                     <option value={'Мультиканальное продвижение'}>Мультиканальное продвижение</option>
                     <option value={'Анализ и экспертиза фармацевтического рынка'}>Анализ и экспертиза фармацевтического рынка</option>
                     <option value={'Анализ целевых аудиторий и потребительских предпочтений'}>Анализ целевых аудиторий и потребительских предпочтений</option>
                     <option value={'Образовательные программы для врачей и провизоров'}>Образовательные программы для врачей и провизоров</option>
                 </select>
             </div>
         </div>
         <div className='flex justify-center'>
         <button
             type="submit"
             className={"uppercase rounded-md border"+ 
             " border-transparent bg-primary py-2 px-4"+
             " text-sm font-medium text-white shadow-sm"+
             " hover:opacity-90 focus:outline-none focus:ring-2"+
             " focus:ring-primary focus:ring-offset-2 opacity-100" + 
             " disabled:opacity-50 disabled:hover:opacity-50 disabled:cursor-not-allowed"
             }
         
             disabled={nameValid && phoneValid && serviceValid ? false:'disabled'}
         >
             Отправить
         </button>
         </div>
         

     </form>
         
        }
       

    </div>
  )
}
export default Form