import React from 'react';
import styles from '../App.module.scss';
import HeaderSocials from "./HeaderSocials";
import Logo from "./Logo";
import {TfiClose} from 'react-icons/tfi';

const Nav = (props)=> {

    const handleClickScroll = (section) => {
       const toSection = document.getElementById(section);
        if (toSection) {
            toSection.scrollIntoView({ behavior: 'smooth' });
            props.setOpen(false);
        }
    };

    return (
            <>
                <nav className={styles.nav}>
                <a href="/"
                   className={styles.navItem}>Главная</a>
                <span onClick={ ()=> handleClickScroll('about')}
                   className={styles.navItem}>О нас</span>
                <span onClick={ ()=> handleClickScroll('services')}
                   className={styles.navItem}>Услуги</span>
                <span onClick={ ()=> handleClickScroll('contacts')}
                   className={styles.navItem}>Контакты</span>
            </nav>
            {/*////Mobile nav////*/}
            <nav className={`mobNav z-[100] lg:hidden flex flex-col fixed md:w-[300px] w-full h-full bottom-0 right-0 shadow-lg opacity-95 p-5 bg-gray-800 duration-1000
                                ${props.open ? 'right-0': 'right-[-100%]'} `
                            }>
                <div className={"mb-5 border-b-2 pb-5 flex justify-between items-center"}>
                    <Logo/>
                    <div
                        className="text-xl hover:text-white cursor-pointer"
                         onClick={ ()=> props.setOpen(false) }
                    >
                        {props.open ? <TfiClose/>:null}

                    </div>

                </div>

                <ul>
                    <li className={"lg:mb-0 mb-3"}> <a href="/" className={styles.navItem}>Главная</a></li>
                    <li className={"lg:mb-0 mb-3"}> <span className={styles.navItem} onClick={ ()=> handleClickScroll('about')}>О нас</span></li>
                    <li className={"lg:mb-0 mb-3"}> <span className={styles.navItem} onClick={ ()=> handleClickScroll('services')}>Услуги</span></li>
                    <li className={"lg:mb-0 mb-3"}> <span className={styles.navItem} onClick={ ()=> handleClickScroll('contacts')}>Контакты</span></li>
                </ul>
                <div className={'mt-auto'}>
                    <div className={"flex justify-around items-center space-x-5"}>
                        <HeaderSocials/>
                    </div>
                </div>
            </nav>
            </>
        );

}

export default Nav;