import React, {Component} from 'react';
import logo from "../img/logo_white.svg";
import styles from "../App.module.scss";

class Logo extends Component {
    render() {
        return (
            <div className={styles.navBrand}>
                <a href="/">
                    <img src={logo} alt="PharmIQ" className={styles.logo}/>
                </a>
            </div>
        );
    }
}

export default Logo;