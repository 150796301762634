import React from 'react';
import styles from '../App.module.scss';
import {services} from '../data/services'
import Button from "./Button";
const Services = () => {

    return (
        <section id={'services'}>
            <h2 className={styles.sectionTitle}>Услуги</h2>

            {
                services.map((item,key) => {

                    return (
                        <div className={'block p-5 mb-10 bg-[#fafafa] rounded-[10px] w-full'} key={key} >
                            <div className={'border-l-[4px] border-primary text-[#383838] pl-3 mb-10'}>
                                <h3 className={'uppercase font-semibold text-[16px] md:text-[20px]'}>
                                    {item.title}
                                </h3>
                                <h5 className={'font-light text-[14px] md:text-[15px]'}>{item.description}</h5>
                            </div>
                            <div className={'grid md:grid-cols-2'}>
                                 <div className="left">
                                     {item.extra.left.title !== null ? <h6>{item.extra.left.title}</h6> :''}
                                     <ul className={'p-3'}>
                                         {
                                             item.extra.left.content.map((li,keyLi)=>{
                                                 return(
                                                     <li key={keyLi} className={styles.bulletLi}>{li}</li>
                                                 )
                                             }

                                            )}
                                     </ul>
                                 </div>
                                {item.extra.right !==null ?
                                    <div className="right">
                                        {item.extra.right.title !== null ? <h6>{item.extra.right.title}</h6> :''}
                                        <ul className={'p-3'}>
                                            {
                                                item.extra.right.content.map((li,keyLi)=>{
                                                        return(
                                                            <li key={keyLi} className={styles.bulletLi}>{li}</li>
                                                        )
                                                    }

                                                )}
                                        </ul>
                                    </div> :
                                    ''
                                }

                            </div>
                            <Button/>
                        </div>
                    )
                })
            }

        </section>
    );

}

export default Services;