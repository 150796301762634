import React, {useState} from 'react';
import styles from '../App.module.scss';
// import HeaderSocials from "./HeaderSocials";
import Nav from "./Nav";
import Logo from "./Logo";
import {RxHamburgerMenu} from "react-icons/rx";


const Header = () =>  {
const [open, setOpen] = useState(false)

        return (
            <div className={styles.header}>
                <Logo/>
                <Nav open={open} setOpen={setOpen}/>
                <div className={"lg:flex hidden items-center space-x-5"}>
                    {/* <HeaderSocials/> */}
                </div>
                <div
                    className={'flex lg:hidden items-center text-3xl cursor-pointer hover:text-white'}
                    onClick={ ()=> setOpen(true)  }
                >
                    {!open ? <RxHamburgerMenu/> : null}
                </div>

            </div>
        );

}

export default Header;