import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
// import Pin from '../img/pin.svg';

const MapY = () => {
    const defaultState = {
        center: [55.731364,37.768983],
        zoom: 15,

    };
    return (

            <YMaps>
                <Map defaultState={defaultState} width={'100%'} height={500}>
                    <Placemark geometry={[55.731364,37.768983]} />
                </Map>
            </YMaps>


    );

}

export default MapY;